import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getCorporateQuestionnaire,
  getCorporateQuestionnaireAnswers,
  postCorporateQuestionnaireAnswers,
  postCorporateEsgCalculation,
} from '@lib/api/index';
import { fetchData } from '@lib/helpers/index';
import EsgComponent from 'components/QuestionnaireComponents/esgComponent';
import StatusBar from 'components/wizard/StatusBar';
import { Trans } from '@lib/i18n/index';
const CorporateEsgQuestionnaire = () => {
  const { companyId, analysisId } = useParams();
  // const userOrgId = JSON.parse(localStorage.getItem('user')).idOrganization || null;

  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [sbShow, setSbShow] = useState(null);
  useEffect(() => {
    if (!companyId || !analysisId) return;
    fetchData(
      getCorporateQuestionnaire,
      setQuestions,
      null,
      [companyId, analysisId],
      null,
      setError,
      setIsLoading
    );
    // setQuestions(testMock);
  }, [companyId, analysisId]);

  const handleStatusBar = (status, message, timeout) => {
    setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
  };

  const shapeAnswers = (data) => {
    const answers = data ? data : [];
    setAnswers(answers.questions);
  };
  useEffect(() => {
    fetchData(
      getCorporateQuestionnaireAnswers,
      null,
      shapeAnswers,
      [companyId, analysisId],
      null,
      setError
    );
  }, []);

  const handleEditQuestionnaire = async (e) => {
    e.preventDefault();
    console.log('answers', answers);
    setSbShow(null);
    setIsLoading(true);
    setError(null);
    const answersToSend = { questions: [...answers] };
    console.log('answersToSend', answersToSend);
    const response = await postCorporateQuestionnaireAnswers(companyId, analysisId, answersToSend);
    if (response.status) {
      const calculateResponse = await postCorporateEsgCalculation(companyId, analysisId);

      if (calculateResponse.status) {
        handleStatusBar('success', calculateResponse.message, true);
        // setVariantRefresh(true);
      } else {
        calculateResponse?.message
          ? setError(calculateResponse.message)
          : setError('Errore nel calcolo dell risultato ESG');
      }
    } else {
      response?.message
        ? setError(response.message)
        : setError('Errore nel salvataggio del questionario');
    }
    setIsLoading(false);
  };

  return (
    <>
      <h1 className='pb-0 mt-5' style={{ marginBottom: '40px' }}>
        <Trans code='A0859' />
      </h1>
      {questions && !!questions.length && answers && (
        <EsgComponent
          questionsData={questions}
          answersData={answers}
          setAnswersData={setAnswers}
          postFunction={handleEditQuestionnaire}
          isLoading={isLoading}
          sbShow={sbShow}
          formId={`esgcorporateform_${analysisId}_${companyId}`}
          shouldStick={false}
          shouldAnswerAll={false}
        />
      )}
      {!questions.length && (
        <div className='simple-box'>
          <h2 className='mb-0'>Attenzione: Nessun questionario trovato</h2>
        </div>
      )}
      {error && <StatusBar status='error' message={error} timeout={false} />}
    </>
  );
};

export default CorporateEsgQuestionnaire;
