import React, { useEffect, useState } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import {
  postCorporateAnalysesKpisCompute,
  putCorporateAnalysesKpisIndicators,
  getCorporateAnalysesKpisIndicators,
  putIsKpiUsable,
} from '@lib/api/index';
import StatusBar from 'components/wizard/StatusBar';
import { fetchData } from '@lib/helpers/index';
import { Trans } from '@lib/i18n/index';
import CorporateEsgKpiIndicatorComponent from './CorporateEsgKpiIndicatorComponent';
const CorporateEsgKpiComponent = ({ kpi, companyId, setRefresh }) => {
  const userCompanyId = JSON.parse(localStorage.getItem('user')).idOrganization || null;
  const { analysisId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sbShow, setSbShow] = useState(null);
  const [indicators, setIndicators] = useState([]);

  useEffect(() => {
    if (!companyId || !analysisId || !kpi.id) return;
    fetchData(
      getCorporateAnalysesKpisIndicators,
      setIndicators,
      null,
      [companyId, analysisId, kpi.id],
      null,
      setError,
      setIsLoading
    );
  }, [companyId, analysisId, kpi.id]);

  const handleStatusBar = (status, message, timeout) => {
    setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
  };

  const onChange = (e, key, index) => {
    // let value = parseInt(e.target.value, 10);
    let value = +e.target.value;
    // if (value > 10) {
    //   value = 10;
    // }
    // if (isNaN(value)) {
    //   value = ''; // or any other value you prefer
    // }

    setIndicators((prevState) => {
      const newState = [...prevState];
      newState[index] = { ...newState[index], [key]: value };
      return newState;
    });
  };
  const submit = async (e) => {
    e.preventDefault();
    setError(null);
    setSbShow(null);
    setIsLoading(true);

    const indicatorArr = indicators;
    const success = [];
    const errors = [];

    // Create an array of promises for the putCorporateAnalysesKpisIndicators requests
    const promises = indicatorArr.map(async (ind) => {
      const { createdAt, updatedAt, ...restIndicator } = ind;
      try {
        const response = await putCorporateAnalysesKpisIndicators(
          companyId,
          analysisId,
          kpi.id,
          ind.id,
          restIndicator
        );
        if (response.status) {
          success.push(ind.id);
        } else {
          errors.push(
            response.message || `Errore nel salvataggio del valore per indicatore ${ind.id}`
          );
        }
      } catch (err) {
        errors.push(`Errore nel salvataggio del valore per indicatore ${ind.id}: ${err.message}`);
      }
    });

    // Wait for all promises to resolve
    await Promise.all(promises);

    if (errors.length === 0) {
      try {
        const response = await postCorporateAnalysesKpisCompute(companyId, analysisId, kpi.id);
        if (response.status) {
          handleStatusBar('success', 'Valore Kpi Calcolato correttamente', true);
          setRefresh(true);
        } else {
          errors.push(response.message || `Errore nel salvataggio del KPI`);
        }
      } catch (err) {
        errors.push(`Errore nel calcolo del KPI: ${err.message}`);
      }
    }

    if (errors.length > 0) {
      setError(errors.join('\n'));
    }

    setIsLoading(false);
  };

  const submitUsable = async (e) => {
    e.preventDefault();
    setSbShow(null);
    setIsLoading(true);
    setError(null);
    const response = await putIsKpiUsable(companyId, analysisId, kpi.kpiId);
    if (response.status) {
      setRefresh(true);
    } else {
      response?.message
        ? setError(response.message)
        : setError('Errore nel salvataggio del questionario');
    }
    setIsLoading(false);
  };
  return (
    <div className='simple-box' style={{ marginTop: '40px' }}>
      <div className='d-flex'>
        <div className='mb-5'>
          <div className='d-flex'>
            <h3 className='m-0'>{kpi.name}</h3>
            {userCompanyId === 1 && (
              <div className='d-flex justify-content-center align-items-center mt-1 ml-4'>
                <div className='text-center'>
                  <Form.Check
                    inline
                    type='switch'
                    id={`switch-corporateesg-${kpi.kpiId}`}
                    name={`switch-corporateesg-${kpi.kpiId}`}
                    aria-label={`switch-corporateesg-${kpi.kpiId}`}
                    className='custom-switch-md'
                    style={{ width: '40px' }}
                    checked={kpi.usable === 1}
                    onChange={(e) => {
                      submitUsable(e);
                    }}
                  />
                </div>
                <div>
                  <Trans code='A0731' />
                </div>
              </div>
            )}
          </div>
          <p className='m-0'>{kpi.description}</p>
        </div>
      </div>

      {!!indicators.length && (
        <>
          <div className='px-5'>
            {indicators.map((el, i) => {
              return (
                <CorporateEsgKpiIndicatorComponent
                  key={i}
                  data={el}
                  index={i}
                  onChange={onChange}
                />
              );
            })}
          </div>
          {userCompanyId === 1 && (
            <div className='d-flex justify-content-center align-items-center'>
              <div>
                <h3 className='m-0'>{kpi.value}</h3>
                <p className='m-0'>
                  <Trans code='A0864' />
                </p>
              </div>
            </div>
          )}
          {/* )} */}
          <Button onClick={submit} className='submit-button' disabled={isLoading}>
            <Trans code='F00046' />
          </Button>
        </>
      )}
      {sbShow}
      {error && <StatusBar status={'error'} message={error} timeout={false} />}
    </div>
  );
};

export default CorporateEsgKpiComponent;
